import React, { useState, useRef, useEffect } from "react";
import ImageModal from "./ImageModal";

function FinalOutput({
  originalImageUrl,
  metallicBracesUrl,
  ceramicBracesUrl,
  placedTeethImageUrl,
}) {
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    updateSliderPosition(e);
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (e) => {
    if (isDragging.current) {
      updateSliderPosition(e);
    }
  };

  const updateSliderPosition = (e) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const position = ((e.clientX - rect.left) / rect.width) * 100;
      setSliderPosition(Math.max(0, Math.min(100, position)));
    }
  };

  const handleImageClick = (e) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const clickPosition = (e.clientX - rect.left) / rect.width;
      const bufferZone = 0.05; // 5% buffer zone on each side

      if (clickPosition < sliderPosition / 100 - bufferZone) {
        setModalImageUrl(metallicBracesUrl);
      } else if (clickPosition > sliderPosition / 100 + bufferZone) {
        setModalImageUrl(ceramicBracesUrl);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="flex flex-col space-y-4 items-center justify-center p-4 min-h-screen bg-gray-200">
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full max-w-screen-lg ">
        {/* Original Image */}
        <div className="w-full md:w-1/3">
          <img
            src={originalImageUrl}
            alt="Original"
            className="w-full h-auto rounded-lg shadow-lg cursor-pointer"
            onClick={() => setModalImageUrl(originalImageUrl)}
          />
          <p className="text-center mt-2 font-semibold text-gray-700">
            Original Image
          </p>
        </div>

        {/* Braces Image with Slider */}
        <div className="w-full md:w-1/3 relative" onClick={handleImageClick}>
          <div className="relative w-full rounded-lg overflow-hidden shadow-lg">
            <img
              src={metallicBracesUrl}
              alt="Metallic Braces"
              className="w-full h-auto cursor-pointer"
            />
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
              <img
                src={ceramicBracesUrl}
                alt="Ceramic Braces"
                className="absolute top-0 left-0 w-full h-auto object-cover cursor-pointer"
                style={{
                  clipPath: `inset(0 0 0 ${sliderPosition}%)`,
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                }}
              />
            </div>
            <div
              className="absolute top-0 bottom-0 w-1 bg-white z-20 shadow-md cursor-ew-resize"
              style={{ left: `${sliderPosition}%` }}
              onMouseDown={handleMouseDown}
            >
              <div className="absolute top-1/2 left-1/2 w-6 h-6 bg-white rounded-full shadow-md transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 12H3M3 12L9 6M3 12L9 18"></path>
                  <path d="M3 12H21M21 12L15 6M21 12L15 18"></path>
                </svg>
              </div>
            </div>
          </div>
          <div ref={sliderRef} onMouseDown={handleMouseDown}></div>
          <div className="flex justify-between text-sm text-gray-600 mt-1">
            <span>Metallic Braces</span>
            <span>Ceramic Braces</span>
          </div>
        </div>

        {/* After Image */}
        <div className="w-full md:w-1/3">
          <img
            src={placedTeethImageUrl}
            alt="After"
            className="w-full h-auto rounded-lg shadow-lg cursor-pointer"
            onClick={() => setModalImageUrl(placedTeethImageUrl)}
          />
          <p className="text-center mt-2 font-semibold text-gray-700">
            After Image
          </p>
        </div>
      </div>

      {/* Modal */}
      {modalImageUrl && (
        <ImageModal
          imageUrl={modalImageUrl}
          onClose={() => setModalImageUrl(null)}
        />
      )}
    </div>
  );
}

export default FinalOutput;
