import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Stage,
  Layer,
  Image,
  Line,
  Transformer,
  Circle,
  Group,
} from "react-konva";

function TeethEditor({
  imageUrl,
  initialData,
  mouthInnerPoints,
  handleApplyBothBraces,
}) {
  const [image, setImage] = useState(null);
  const [metallicBracesImage, setMetallicBracesImage] = useState(null);
  const [ceramicBracesImage, setCeramicBracesImage] = useState(null);
  const [faceData, setFaceData] = useState(initialData);
  const [selectedId, setSelectedId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [braceSizes, setBraceSizes] = useState([
    { width: 6, height: 6 },
    { width: 8, height: 8 },
    { width: 8, height: 8 },
    { width: 10, height: 10 },
    { width: 10, height: 10 },
    { width: 8, height: 8 },
    { width: 8, height: 8 },
    { width: 6, height: 6 },
  ]);
  const [braceType, setBraceType] = useState("metallic");
  const [groupTransform, setGroupTransform] = useState({
    x: 0,
    y: 0,
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
  });
  const [groupSelected, setGroupSelected] = useState(false);
  const stageRef = useRef(null);
  const transformerRef = useRef(null);
  const bracesRefs = useRef([]);
  const groupRef = useRef(null);

  const centerImage = useCallback(() => {
    if (stageRef.current && mouthInnerPoints) {
      const stage = stageRef.current;
      // Calculate the bounding box of the teeth_out_line
      const points = mouthInnerPoints;
      let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;
      points.forEach((point) => {
        const [x, y] = point;
        if (x < minX) minX = x;
        if (x > maxX) maxX = x;
        if (y < minY) minY = y;
        if (y > maxY) maxY = y;
      });
      // Calculate the center of the bounding box
      const centerX = (minX + maxX) / 2;
      const centerY = (minY + maxY) / 2;
      // Center the bounding box within the stage
      const offsetX = stage.width() / 2 - centerX;
      const offsetY = stage.height() / 2 - centerY;
      stage.position({ x: offsetX, y: offsetY });
      stage.batchDraw();
    }
  }, [mouthInnerPoints]);

  useEffect(() => {
    const img = new window.Image();
    img.src = imageUrl;
    img.onload = () => {
      setImage(img);
      centerImage(img);
    };

    const metallicImg = new window.Image();
    metallicImg.src = "braces.png";
    metallicImg.onload = () => {
      setMetallicBracesImage(metallicImg);
    };

    const ceramicImg = new window.Image();
    ceramicImg.src = "braces2.png";
    ceramicImg.onload = () => {
      setCeramicBracesImage(ceramicImg);
    };
  }, [imageUrl, centerImage]);

  useEffect(() => {
    console.log("teeth_points", faceData.teeth_points);
    console.log("groupTransform", groupTransform);
  }, [faceData, groupTransform]);

  const handleBraceDragMove = useCallback(
    (index, e) => {
      if (!editMode) return;
      const updatedX = e.target.x();
      const updatedY = e.target.y();
      setFaceData((prevData) => {
        const newData = { ...prevData };
        newData.teeth_points[index] = [updatedX, updatedY];
        return newData;
      });
    },
    [editMode]
  );

  const handleBraceTransform = useCallback((index, e) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    const newWidth = node.width() * scaleX;
    const newHeight = node.height() * scaleY;

    setBraceSizes((prevSizes) => {
      const newSizes = [...prevSizes];
      newSizes[index] = { width: newWidth, height: newHeight };
      return newSizes;
    });

    const updatedX = node.x();
    const updatedY = node.y();

    setFaceData((prevData) => {
      const newData = { ...prevData };
      newData.teeth_points[index] = [updatedX, updatedY];
      return newData;
    });
  }, []);

  const handleGroupTransform = useCallback((e) => {
    const node = e.target;
    setGroupTransform((prev) => ({
      ...prev,
      rotation: node.rotation(),
      scaleX: node.scaleX(),
      scaleY: node.scaleY(),
    }));
  }, []);

  const renderBraces = useCallback(
    (points) => {
      if (!points || (!metallicBracesImage && !ceramicBracesImage)) return null;
      const currentBracesImage =
        braceType === "metallic" ? metallicBracesImage : ceramicBracesImage;

      const bracesElements = points.map((point, i) => {
        const size = braceSizes[i] || { width: 10, height: 10 };
        const x = point[0];
        const y = point[1];

        if (editMode) {
          return (
            <Circle
              key={`brace-${i}`}
              ref={(el) => (bracesRefs.current[i] = el)}
              x={x}
              y={y}
              radius={size.width / 2}
              fill={braceType === "metallic" ? "#808080" : "#FFFFFF"}
              draggable
              onDragMove={(e) => handleBraceDragMove(i, e)}
              onClick={(e) => {
                e.cancelBubble = true;
                setSelectedId(i);
                setGroupSelected(false);
              }}
            />
          );
        } else {
          return (
            <Image
              key={`brace-${i}`}
              ref={(el) => (bracesRefs.current[i] = el)}
              image={currentBracesImage}
              x={x - size.width / 2}
              y={y - size.height / 2}
              width={size.width}
              height={size.height}
              draggable={false}
            />
          );
        }
      });

      const linePoints = points.flatMap((point) => [point[0], point[1]]);

      return (
        <Group
          ref={groupRef}
          x={groupTransform.x}
          y={groupTransform.y}
          rotation={groupTransform.rotation}
          scaleX={groupTransform.scaleX}
          scaleY={groupTransform.scaleY}
          onTransform={handleGroupTransform}
          onClick={(e) => {
            if (!editMode) {
              e.cancelBubble = true;
              setGroupSelected(true);
              setSelectedId(null);
            }
          }}
        >
          <Line
            points={linePoints}
            stroke={braceType === "metallic" ? "#C0C0C0" : "#E0E0E0"}
            strokeWidth={2}
            lineCap="round"
            lineJoin="round"
            tension={0.5}
            shadowColor="black"
            shadowBlur={5}
            shadowOffsetX={2}
            shadowOffsetY={2}
          />
          {bracesElements}
        </Group>
      );
    },
    [
      metallicBracesImage,
      ceramicBracesImage,
      handleBraceDragMove,
      editMode,
      braceSizes,
      braceType,
      groupTransform,
      handleGroupTransform,
    ]
  );

  const handleWheel = useCallback((e) => {
    e.evt.preventDefault();
    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Limit the zoom level to ensure the image remains visible
    if (newScale < 0.1) return; // Prevent zooming out too much

    const newPos = {
      x: stage.getPointerPosition().x - mousePointTo.x * newScale,
      y: stage.getPointerPosition().y - mousePointTo.y * newScale,
    };

    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();
  }, []);

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setSelectedId(null);
    setGroupSelected(false);
  };

  const handleBraceTypeChange = (e) => {
    setBraceType(e.target.value);
  };

  return (
    <div className="relative w-full h-screen flex flex-col items-center justify-center">
      <div className="absolute top-4 left-4 right-4 bg-white bg-opacity-75 p-4 rounded-lg shadow-md z-10">
        <p className="text-lg font-semibold text-gray-800">
          Toggle "Edit Braces" to adjust individual braces. When off, move and
          resize all braces together.
        </p>
        <p className="text-sm text-gray-600 mt-2">
          Use the dropdown to switch brace types. Click "Done" when finished.
        </p>
      </div>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        ref={stageRef}
        onClick={() => {
          setSelectedId(null);
          setGroupSelected(false);
        }}
        onWheel={handleWheel}
        draggable
        className="flex-grow"
      >
        <Layer>
          {image && (
            <Image
              image={image}
              width={image.width}
              height={image.height}
              alt="Face for smile line editing"
            />
          )}

          {renderBraces(faceData.teeth_points)}

          {((editMode && selectedId !== null) ||
            (!editMode && groupSelected)) && (
            <Transformer
              ref={transformerRef}
              boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
              onTransformEnd={(e) => {
                if (groupSelected) {
                  handleGroupTransform(e);
                } else if (selectedId !== null) {
                  handleBraceTransform(selectedId, e);
                }
              }}
              anchorSize={8}
              anchorCornerRadius={4}
              borderStroke="#0000FF"
              borderStrokeWidth={0.5}
              padding={1}
              nodes={
                groupSelected
                  ? [groupRef.current]
                  : [bracesRefs.current[selectedId]]
              }
            />
          )}
        </Layer>
      </Stage>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-4 bg-white bg-opacity-75 p-4 rounded-lg shadow-md z-10 ">
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={editMode}
            onChange={toggleEditMode}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ml-3 text-sm font-medium text-gray-900">
            Edit Braces
          </span>
        </label>
        <select
          value={braceType}
          onChange={handleBraceTypeChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="metallic">Metallic Braces</option>
          <option value="ceramic">Ceramic Braces</option>
        </select>
        <button
          onClick={() => {
            handleApplyBothBraces(
              faceData.teeth_points,
              braceSizes,
              groupTransform
            );
          }}
          className=" bg-blue-600 text-white rounded px-4 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default TeethEditor;
