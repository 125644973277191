import React, { useRef } from "react";
import { RiImageAddFill } from "react-icons/ri";
import { FaLink } from "react-icons/fa6";

import rrSmileLogo from "../assets/rrsmile-white.png";
import rrDentalLabsLogo from "../assets/RR_DentalLabsPvtLtd.png";
import immediateLoadMastersLogo from "../assets/immediate-load-master.png";
import fireAndForgetLogo from "../assets/fire-and-forget.png";
import iDentClearLogo from "../assets/I-dent-Aligners-logo.png";

function ImageUploader({ handleImageUpload, isLoading }) {
  const instructionText =
    "Please upload an image of only one person with a full face. The person should be facing the camera, and the eyes, nose, and teeth should be visible.";

  const fileInputRef = useRef(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (isLoading) return;
    const file = event.dataTransfer?.files?.[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  const handleInputChange = (event) => {
    if (isLoading) return;
    const file = event.target?.files?.[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-white">
      <div className="w-full lg:w-1/2 bg-gray-900 flex flex-col justify-center items-center p-6 lg:p-12">
        <img
          src={rrSmileLogo}
          alt="RR Smile AI"
          className="w-48 lg:w-64 mb-6 lg:mb-8"
        />
        <h2 className="text-yellow-500 text-xl lg:text-2xl font-bold mb-4 w-full">
          ABOUT OUR COMPANY
        </h2>
        <p className="text-white text-sm lg:text-base">
          Established in 2000, RR Dental Labs has over 24 years of experience
          delivering high-quality dental services. We are at the forefront of
          digital dental technology, utilizing advanced processes like Metal
          Milling and 3D printing. Our expertise includes artificial teeth,
          implant technology, and custom aligners.
        </p>
        <p className="text-white text-sm lg:text-base mt-4">
          Innovative methods such as "fire & forget" and "single drill implant
          placement" set us apart in the industry. We adhere to international
          standards, ensuring precision in products like Fire & Forget and
          I-Dent clear aligners.
        </p>
        <p className="text-white text-sm lg:text-base mt-4">
          Our dedicated team combines experience and innovation, fostering a
          culture of continuous improvement. We are committed to ensuring
          lifelong dental health and beautiful smiles for our clients. Trust RR
          Dental Labs for exceptional service and customer delight.
        </p>
      </div>
      <div className="w-full lg:w-1/2 flex flex-col justify-center items-center p-6 lg:p-12">
        <img
          src={rrDentalLabsLogo}
          alt="RR Dental Labs Pvt. Ltd."
          className="w-full max-w-md mb-12"
        />
        <div className="flex flex-wrap justify-between max-w-md w-full mb-6 lg:mb-8">
          <img
            src={iDentClearLogo}
            alt="i.Dent Aligners"
            className="w-full sm:w-1/2 object-contain"
          />
          <img
            src={fireAndForgetLogo}
            alt="Fire & Forget"
            className="w-full sm:w-1/2 object-contain"
          />
          <img
            src={immediateLoadMastersLogo}
            alt="Immediate Load Masters"
            className="w-full sm:w-1/2 mx-auto object-contain"
          />
        </div>
        <div className="w-full max-w-md border-t border-gray-300 border-dashed mb-6"></div>
        <div className="w-full max-w-md">
          <div
            className="bg-[#309ed5] text-white p-4 text-center cursor-pointer flex items-center justify-center"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
            role="button"
            tabIndex={0}
            aria-label={`Upload image. ${instructionText}`}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleInputChange}
              disabled={isLoading}
              className="hidden"
            />

            <span className="text-lg font-medium">
              {isLoading ? "Uploading..." : "Drag & Drop or Upload"}
            </span>
            <RiImageAddFill className="ml-2" size={24} />
          </div>
          <div className="mt-4 lg:mt-6 text-center">
            <p className="text-xs lg:text-sm text-black">{instructionText}</p>
          </div>
        </div>
        <div className="absolute bottom-4">
          <a
            href="https://www.rrdentallab.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black text-center"
          >
            <FaLink className="inline-block mr-2" />
            www.rrdentallab.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default ImageUploader;
