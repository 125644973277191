import React, { useState } from "react";
import SmileLineEditor from "./components/SmileLineEditor";
import TeethEditor from "./components/TeethEditor";
import FinalOutput from "./components/FinalOutput";
import TeethLineEditor from "./components/TeethLineEditor";
import ImageUploader from "./components/ImageUploader";
import Loader from "./components/Loader";
import * as fal from "@fal-ai/serverless-client";

function App() {
  const [smileLinePoints, setSmileLinePoints] = useState(null);
  const [teethPoints, setTeethPoints] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [metallicBracesUrl, setMetallicBracesUrl] = useState(null);
  const [ceramicBracesUrl, setCeramicBracesUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [teethCoords, setTeethCoords] = useState(null);
  const [placedTeethImageUrl, setPlacedTeethImageUrl] = useState(null);

  const detectTeeth = async (imageUrl) => {
    setIsLoading(true);
    setError(null);

    try {
      // Fetch the image from the URL
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Convert the fetched image to a Blob
      const blob = await response.blob();

      // Create a File object from the Blob
      const file = new File([blob], "image.jpg", { type: blob.type });

      const formData = new FormData();
      formData.append("file", file);

      // Send the file to the server
      const apiResponse = await fetch(
        "https://api.rrsmileai.com/detect_teeth",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!apiResponse.ok) {
        throw new Error(`HTTP error! status: ${apiResponse.status}`);
      }

      const result = await apiResponse.json();
      setSmileLinePoints(result);
      estimateTeethPoints(result.teeth_out_line, imageUrl);
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to process the image. Please try again.");
    }
  };

  const getPlacedTeethImageUrl = async (imageUrl) => {
    setIsLoading(true);
    try {
      fal.config({
        credentials:
          "b65d2d04-d2cc-41b2-bea5-516b43139e64:ae5f9aaaa9b1953eda8930b97b69d5c1",
      });

      console.log("thisis working", imageUrl);

      // Extract the dimensions of the image from the base64 string
      const { width, height } = await getImageDimensions(imageUrl);

      // Subscribe to the image-to-image transformation function
      const result = await fal.subscribe("fal-ai/flux/dev/image-to-image", {
        // Input data for the function
        input: {
          image_url: imageUrl,
          prompt:
            "photo of this person with upper and lower teeth fixed and no gaps and perfect smile",
          strength: 0.78,
          seed: 4953961,
          sync_mode: true,
          image_size: {
            width: width,
            height: height,
          },
        },
        // Enable logging
        logs: true,

        // Callback function to handle updates in the queue
        onQueueUpdate: (update) => {
          // Check if the status is "IN_PROGRESS"
          if (update.status === "IN_PROGRESS") {
            // Log each message from the logs array to the console
            update.logs.map((log) => log.message).forEach(console.log);
          }
        },
      });

      console.log("Subscription result:", result);
      const image = await blendMouth(imageUrl, result.images[0].url);
      console.log(imageUrl, result.images[0].url);

      detectTeeth(image);
    } catch (error) {
      console.error("Error subscribing to function:", error);
    }
    setIsLoading(false);
  };

  // Function to get the dimensions of an image from a base64 string
  const getImageDimensions = (base64Image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = base64Image;
    });
  };

  const handleImageUpload = async (file) => {
    setIsLoading(true);
    if (!file) return;
    setError(null);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const uploadedImageUrl = URL.createObjectURL(file);

      const image = new Image();
      image.src = uploadedImageUrl;

      image.onload = async () => {
        const maxHeight = 800;
        let width = image.width;
        let height = image.height;

        if (height > maxHeight) {
          const scaleFactor = maxHeight / height;
          width *= scaleFactor;
          height = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob(async (blob) => {
          const resizedFile = new File([blob], file.name, { type: file.type });
          const reader = new FileReader();
          reader.onloadend = async () => {
            const imageBase64 = reader.result;

            // Convert base64 to Blob
            const base64Response = await fetch(
              `data:${file.type};base64,${imageBase64.split(",")[1]}`
            );
            const blob = await base64Response.blob();

            // Create a URL for the Blob
            const blobUrl = URL.createObjectURL(blob);

            // Set the URL in setUploadedImageUrl
            setUploadedImageUrl(blobUrl);

            console.log("this is blogurl", blobUrl);

            // Call the getPlacedTeethImageUrl with the resized image
            await getPlacedTeethImageUrl(imageBase64);
          };
          await reader.readAsDataURL(resizedFile);
        }, file.type);
      };
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to process the image. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const estimateTeethPoints = async (teeth_outline, imageUrl) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      teeth_outline: teeth_outline,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.rrsmileai.com/estimate_braces_point",
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setTeethPoints(result);
      const braceSizes = [
        { width: 6, height: 6 },
        { width: 8, height: 8 },
        { width: 8, height: 8 },
        { width: 10, height: 10 },
        { width: 10, height: 10 },
        { width: 8, height: 8 },
        { width: 8, height: 8 },
        { width: 6, height: 6 },
      ];

      const groupTransform = {
        x: 0,
        y: 0,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      };
      handleApplyBothBraces(
        result.teeth_points,
        braceSizes,
        groupTransform,
        imageUrl
      );
    } catch (error) {
      console.error("Error estimating teeth points:", error);
      setError("Failed to estimate teeth points. Please try again.");
    }
  };

  const applyBraces = async (
    teethPoints,
    braceSizes,
    braceType,
    transform,
    placedTeethImageUrl
  ) => {
    setIsLoading(true);
    setError(null);
    console.log(placedTeethImageUrl);

    const formdata = new FormData();
    formdata.append(
      "file",
      await fetch(placedTeethImageUrl).then((r) => r.blob()),
      "image.jpg"
    );
    formdata.append("teeth_points", JSON.stringify(teethPoints));
    formdata.append("brace_sizes", JSON.stringify(braceSizes));
    formdata.append("type", braceType);
    formdata.append("transform", JSON.stringify(transform));

    try {
      const response = await fetch("https://api.rrsmileai.com//apply_braces", {
        method: "POST",
        body: formdata,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);

      if (braceType === "metallic") {
        setMetallicBracesUrl(imageUrl);
      } else {
        setCeramicBracesUrl(imageUrl);
      }
    } catch (error) {
      console.error("Error applying braces:", error);
      setError(`Failed to apply ${braceType} braces. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getTeethCoords = async (teethOutline, imageUrl) => {
    setIsLoading(true);
    setError(null);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      teeth_outline: teethOutline,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.rrsmileai.com/get_teeth_coords",
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setTeethCoords(result.teeth_coords);
      const braceSizes = [
        { width: 6, height: 6 },
        { width: 8, height: 8 },
        { width: 8, height: 8 },
        { width: 10, height: 10 },
        { width: 10, height: 10 },
        { width: 8, height: 8 },
        { width: 8, height: 8 },
        { width: 6, height: 6 },
      ];

      const groupTransform = {
        x: 0,
        y: 0,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      };
      handleApplyBothBraces(
        result.teeth_coords,
        braceSizes,
        groupTransform,
        imageUrl
      );
    } catch (error) {
      console.error("Error getting teeth coordinates:", error);
      setError("Failed to get teeth coordinates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const placeTeeth = async (teethCoords) => {
    setIsLoading(true);
    setError(null);

    const formdata = new FormData();
    formdata.append(
      "file",
      await fetch(uploadedImageUrl).then((r) => r.blob()),
      "image.jpg"
    );
    formdata.append("teeth_coords", JSON.stringify(teethCoords));

    try {
      const response = await fetch("https://api.rrsmileai.com/place_teeth", {
        method: "POST",
        body: formdata,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setPlacedTeethImageUrl(imageUrl);
    } catch (error) {
      console.error("Error placing teeth:", error);
      setError("Failed to place teeth. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyBothBraces = async (
    teethPoints,
    braceSizes,
    transform,
    imageUrl
  ) => {
    await applyBraces(teethPoints, braceSizes, "metallic", transform, imageUrl);
    await applyBraces(teethPoints, braceSizes, "ceramic", transform, imageUrl);
  };

  const blendMouth = async (beforeImageUrl, afterImageUrl) => {
    setIsLoading(true);
    setError(null);

    try {
      // Fetch the before image from the URL
      const beforeResponse = await fetch(beforeImageUrl);
      if (!beforeResponse.ok) {
        throw new Error(`HTTP error! status: ${beforeResponse.status}`);
      }

      // Convert the fetched before image to a Blob
      const beforeBlob = await beforeResponse.blob();

      // Create a File object from the Blob
      const beforeFile = new File([beforeBlob], "before.jpeg", {
        type: beforeBlob.type,
      });

      console.log("beforeFile", beforeFile);

      // Fetch the after image from the URL
      const afterResponse = await fetch(afterImageUrl);
      if (!afterResponse.ok) {
        throw new Error(`HTTP error! status: ${afterResponse.status}`);
      }

      // Convert the fetched after image to a Blob
      const afterBlob = await afterResponse.blob();

      // Create a File object from the Blob
      const afterFile = new File([afterBlob], "after.jpeg", {
        type: afterBlob.type,
      });

      const formData = new FormData();
      formData.append("before", beforeFile);
      formData.append("after", afterFile);

      // Send the files to the server
      const apiResponse = await fetch("https://api.rrsmileai.com/blend_mouth", {
        method: "POST",
        body: formData,
      });

      if (!apiResponse.ok) {
        throw new Error(`HTTP error! status: ${apiResponse.status}`);
      }

      // Handle the response as a Blob (image)
      const resultBlob = await apiResponse.blob();
      const imageUrl = URL.createObjectURL(resultBlob);
      setPlacedTeethImageUrl(imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to process the images. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      {!uploadedImageUrl && (
        <ImageUploader
          handleImageUpload={handleImageUpload}
          isLoading={isLoading}
        />
      )}

      {isLoading && <Loader />}
      {error && <p className="error">{error}</p>}
      {/*

      {uploadedImageUrl && smileLinePoints && !teethPoints && (
        <SmileLineEditor
          imageUrl={uploadedImageUrl}
          initialData={smileLinePoints}
          estimateTeethPoints={estimateTeethPoints}
          getTeethCoords={getTeethCoords}
        />
      )}
      {teethPoints && !metallicBracesUrl && (
        <TeethEditor
          imageUrl={uploadedImageUrl}
          mouthInnerPoints={smileLinePoints.teeth_out_line}
          initialData={teethPoints}
          handleApplyBothBraces={handleApplyBothBraces}
        />
      )}
      {metallicBracesUrl && ceramicBracesUrl && !placedTeethImageUrl && (
        <TeethLineEditor
          imageUrl={uploadedImageUrl}
          teethCoords={teethCoords}
          mouthInnerPoints={smileLinePoints.teeth_out_line}
          placeTeeth={placeTeeth}
        />
      )}
   */}
      {/* {metallicBracesUrl && ceramicBracesUrl && placedTeethImageUrl && (
        <FinalOutput
          originalImageUrl={uploadedImageUrl}
          metallicBracesUrl={metallicBracesUrl}
          ceramicBracesUrl={ceramicBracesUrl}
          placedTeethImageUrl={placedTeethImageUrl}
        />
      )} */}
      {placedTeethImageUrl && metallicBracesUrl && ceramicBracesUrl && (
        <FinalOutput
          originalImageUrl={uploadedImageUrl}
          metallicBracesUrl={metallicBracesUrl}
          ceramicBracesUrl={ceramicBracesUrl}
          placedTeethImageUrl={placedTeethImageUrl}
        />
      )}
    </div>
  );
}

export default App;
