import React, { useState, useRef, useEffect } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

const ImageModal = ({ imageUrl, onClose }) => {
  const [isZooming, setIsZooming] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleWheel = (e) => {
    e.preventDefault();
    const newScale = Math.max(1, scale + e.deltaY * -0.01);
    setScale(newScale);
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      setIsZooming(true);
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const initialDistance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );
      e.target.dataset.initialDistance = initialDistance;
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2 && isZooming) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const currentDistance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );
      const initialDistance = parseFloat(e.target.dataset.initialDistance);
      const newScale = (currentDistance / initialDistance) * scale;
      setScale(Math.max(1, newScale));
    }
  };

  const handleTouchEnd = () => {
    setIsZooming(false);
  };

  useEffect(() => {
    const image = imageRef.current;
    image.addEventListener("wheel", handleWheel, { passive: false });
    image.addEventListener("touchstart", handleTouchStart, { passive: false });
    image.addEventListener("touchmove", handleTouchMove, { passive: false });
    image.addEventListener("touchend", handleTouchEnd, { passive: false });

    return () => {
      image.removeEventListener("wheel", handleWheel);
      image.removeEventListener("touchstart", handleTouchStart);
      image.removeEventListener("touchmove", handleTouchMove);
      image.removeEventListener("touchend", handleTouchEnd);
    };
  }, [scale, isZooming]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white text-2xl z-10"
      >
        &times;
      </button>
      <motion.img
        ref={imageRef}
        src={imageUrl}
        alt="Fullscreen"
        className="max-w-full max-h-full"
        style={{ scale, x: position.x, y: position.y }}
        drag={isZooming ? false : "true"}
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        onDrag={(e, info) => {
          setPosition({ x: info.offset.x, y: info.offset.y });
        }}
      />
    </div>
  );
};

export default ImageModal;
